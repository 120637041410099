<template>
    <div class="content-wrapper">
        <app-page-top-section :title="$t('projects')" icon="briefcase">
            <app-default-button
                :title="$fieldTitle('add', 'project', true)"
                v-if="$can('create_designations')"
                @click="openModal()"
            />
        </app-page-top-section>
        <app-table
            id="project-table"
            :options="options"
            @action="triggerActions"
        />
    </div>
</template>

<script>
    import HelperMixin from "../../../../../common/Mixin/Global/HelperMixin";
    import ProjectMixin from "../../../Mixins/ProjectMixin";
    import {PROJECTS} from "../../../../Config/ApiUrl";
    export default {
        name: "Projects",
        mixins: [HelperMixin, ProjectMixin],
        data() {
            return {
                isModalActive: false,
                selectedUrl: '',
            }
        },
        methods: {
            triggerActions(row, action, active) {
                if (action.title === this.$t('edit')) {
                    this.selectedUrl = `${DESIGNATION}/${row.id}`;
                    this.isModalActive = true;
                } else {
                    this.getAction(row, action, active)
                }
            }
        }
    }
</script>